import React, { FC } from 'react';
import { Link } from 'gatsby';

import Image from 'common/Image';

import { RecipeListDesktopProps } from './models';

import './RecipeListDesktop.scss';

const RecipeListDesktop: FC<RecipeListDesktopProps> = ({ items }) => (
  <div className="recipe-list__items pt-5 px-5">
    <div className="container-fluid">
      <div className="row">
        {items.map((recipe) => (
          <div className="col col-md-4 mb-4" key={recipe.id} data-testid="recipe-list-desktop-item">
            <div className="recipe-list__items-item">
              <div className="recipe-list__items-item-image">
                <Link to={recipe.url}>
                  <Image imageData={recipe.thumbnail} alt={recipe.thumbnail.altText} />
                </Link>
              </div>
              <Link
                className="p-2 border-0 d-flex align-items-center justify-content-center"
                to={recipe.url}
              >
                {recipe.title}
              </Link>
            </div>
          </div>
        ))}
      </div>
    </div>
  </div>
);

export default RecipeListDesktop;
