import React, { FC } from 'react';
import { Carousel } from 'react-responsive-carousel';
import { Link } from 'gatsby';

import Image from 'common/Image';

import { RecipeListMobileProps } from './models';

import './RecipeListMobile.scss';

const RecipeListMobile: FC<RecipeListMobileProps> = ({ items }) => (
  <div className="pt-5">
    <Carousel showThumbs={false} showIndicators showArrows showStatus={false} infiniteLoop>
      {items.map((recipe) => (
        <div className="recipe-list__items-item" key={recipe.id} data-testid="recipe-mobile-item">
          <div className="recipe-list__items-item-image">
            <Link to={recipe.url}>
              <Image imageData={recipe.thumbnail} alt={recipe.thumbnail.altText} />
            </Link>
          </div>
          <Link
            className="p-2 border-0 d-flex align-items-center justify-content-center"
            to={recipe.url}
          >
            {recipe.title}
          </Link>
        </div>
      ))}
    </Carousel>
  </div>
);

export default RecipeListMobile;
